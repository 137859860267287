@import '../../theme/carbon_colors';

.scc--boxdetail__header {
  background: #fff;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.scc--boxdetail__header--boxname {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
}

.scc--boxdetail__header--rebootDevice-button {
  float: right;
  vertical-align: middle;
  margin-top: 10px;
  margin-right: 10px;
}

.scc--boxdetail__header--retrieveLogs-button {
  float: right;
  vertical-align: middle;
  margin-top: 10px;
}

.scc--boxdetail__header--boxId {
  margin-top: 10px;
  font-size: 0.75rem;
}

.scc--boxdetail--streamId {
  font-size: 0.75rem;
  margin-bottom: 10px;
}

.scc--boxcontextual--table--copyid-button,
.scc--boxcontextual--table--copyid-button:hover,
.scc--boxcontextual--table--copyid-button:focus {
  border: 0;
  background: transparent;
}

.scc--boxcontextual--triggerId {
  font-size: 0.75rem;
}

.scc--boxdetail--copyid-button {
  border: 0;
  margin-left: 3px;
}

.scc--boxdetail__header--boxname__notset {
  color: $text-grey;
}

.scc--box_id {
  font-size: 0.75rem;
}

.scc--deviceconfig-container {
  overflow: hidden;
  height: 100%;

  .bx--grid,
  .bx--row {
    overflow: auto;
    height: 100%;

    & > div {
      height: 100%;
    }
  }

  .bx--grid {
    margin-left: 1rem;
  }
}

.bx--form {
  margin-bottom: 1rem;
}

.bx--tooltip {
  width: 18rem;
  max-width: 18rem;

  .bx--tooltip__caret {
    left: auto;
    right: 5px;
  }
}

.bx--tooltip__trigger:focus {
  outline: none !important;
}

.bx--text-input--password__visibility__toggle {
  svg {
    fill: $text_light !important;
    outline: none !important;
  }

  &:hover svg,
  &:focus svg {
    fill: $text-grey !important;
  }
}

.scc--tooltip--title {
  color: #ffffff;
  font-size: 1rem;
}

input[data-invalid],
.bx--text-input__field-wrapper[data-invalid],
.bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid,
.bx--select-input__wrapper[data-invalid],
.bx--list-box[data-invalid],
.bx--combo-box[data-invalid] .bx--text-input {
  outline: none;
  border-color: $error;

  &:active,
  &:focus {
    outline: none;
    border-color: $error;
  }
}

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select-input__wrapper[data-invalid] .bx--select-input:focus {
  outline: none;
  border: 1px solid $error;
}

.bx--select-input__wrapper {
  width: 100%;
}

.scc--boxdetail--container {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: left 0.3s ease-in-out;
  left: 100%;

  &.shown {
    left: 10rem;
  }

  .scc--close-button {
    position: absolute;
    top: 8px;
    left: 5px;
  }
}

.scc--close-button {
  display: block;
}

.scc--fill-green {
  fill: $light-green;

  a &:hover,
  a &:focus {
    fill: $green;
  }
}

.scc--boxdetail--canvas {
  position: relative;

  .bx--btn--primary {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
  }
}
