@import '../../theme/carbon_colors';

.scc--boxcontextual-container {
  height: 100%;
  overflow: hidden;

  .bx--row,
  .bx--grid {
    height: 100%;
    & > div {
      height: 100%;
      overflow: auto;
    }
  }

  .ScrollbarsCustom-TrackY {
    top: 2rem !important;
    right: -11px !important;
    height: calc(100% - 3rem) !important;
  }
}

.scc--boxcontextual-container--sceneNote {
  background: $green;
  padding: 10px;
  margin-bottom: 10px;
  a {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
  }
}

.scc--flex--column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scc--boxcontextual--table {
  margin-bottom: 1rem;
}

#scc--draw--canvas {
  position: relative;
  overflow: hidden;
  margin: auto;
  height: 100%;
}

.scc--boxcontextual--btn-save--container {
  position: sticky;
  bottom: 0.625rem;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.scc--draw--canvas--wrapper {
  background: $form-fields-background;
  position: relative;
}
