.scc--solutions--streams-empty {
  text-align: center;
  margin-top: 20px;
}

.scc--solutions--remove-stream-button {
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.scc--solutions--remove-stream-button:hover {
  background: transparent;
}

.scc--solutions--streams {
  margin-top: 25px;
}

.scc--solutions--add-stream-option {
  font-weight: bold;
}

.scc--solutions--stream--collapse {
  background-color: transparent;
  border-left: none;
  border-right: none;
}

.scc--solutions--stream--collapse-item:hover {
  background-color: #e5e5e5;
}

.scc--solutions--stream--collapse-item .ant-collapse-header {
  font-weight: bold;
  font-size: 1rem;
}
