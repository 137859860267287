@import '../../theme/carbon_colors';

.scc--datadiscovery--widget-anprImage {
  margin-top: 25px;
  margin-bottom: -10px;
  display: flex;
  justify-content: center;
}

.scc--datadiscovery--widget-anprImage-error {
  padding: 10px;
}

.scc--datadiscovery--widget-exportbutton {
  background: $green;
  border-color: $green;
  float: right;
  margin-left: 10px;
}
