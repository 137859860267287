.scc--account__info {
  font-size: 1rem;
}

.scc--account__name {
  display: inline-block;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  color: #000000;

  [aria-expanded='true'] &,
  [aria-expanded]:hover & {
    color: #ffffff;
  }
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
  + .bx--header__menu {
  left: 0;
  right: -1rem;
  font-size: 1rem;
  width: auto;
}

a.bx--header__menu-item[role='menuitem'] {
  border: none;
  min-width: 12.5rem;
  font-size: 1rem;

  &.last {
    margin-right: -1rem;
  }
}
