@import '../../theme/carbon_colors';
// === BASIC ===

body {
  background: rgba(0, 0, 0, 0.1);
}

a,
a:visited {
  color: $link-01;
  text-decoration: none;
}

a:hover,
a:focus {
  color: $focus;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $green;
  font-weight: bold;
}

// === UTILITIES ===

.center {
  text-align: center;
}

.silent {
  font-size: 0.75rem;
  color: #dddddd;
  font-weight: bold;
}

a.silent:hover,
a.silent:active {
  color: #666;
}

// === COMPONENT ===

.scc--app {
  background: #ffffff;
  display: flex;
  position: relative;
}

.scc--main {
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
}

.scc--content {
  flex: 1;
  overflow: hidden;
}

// === OVERRIDES ===

.bx--grid {
  padding: 0 1rem;
}

.bx--btn {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  min-height: 2.5rem;
  padding: 10px;
  line-height: 1.25rem;
}

.bx--btn--primary {
  &:active,
  &:focus {
    outline: none;
  }
}

.bx--sidenav {
  background: $light_green;
  position: relative;
}

.scc--full-height {
  height: 100%;
}

#root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.notifications-component {
  position: static !important;
}

.notification-container--top-right {
  right: 3rem !important;
  top: 3.75rem !important;
  width: 270px;
  z-index: 9999;
}

.notification__item--info,
.notification__item--info .notification__timer {
  background-color: $form-fields-background !important;

  .notification-title,
  .notification-message {
    color: #000000 !important;
  }
}

.notification__item--success,
.notification__item--success .notification__timer {
  background-color: $green !important;
}

.notification__item--danger,
.notification__item--danger .notification__timer {
  background-color: $error !important;
}

.notification__item--info {
  border-left-color: $green !important;
}

.notification__item--success,
.notification__item--danger {
  border-left: none !important;
}
