@import '../../theme/carbon_colors';

.scc--camera-feed--wrapper {
  background: #f2f5f7;
  position: relative;
  height: 100%;
}
.scc--camera-feed--centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scc--camera-feed--loading {
  .bx--loading {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.scc--camera-feed--icon {
  height: 1.5rem;
  text-align: center;
  display: block;
}

.scc--camera-feed--header,
.scc--camera-feed--text {
  font-size: 1rem;
  color: $text_light;
  text-align: center;
}

.scc--camera-feed--time {
  color: #ffffff;
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  z-index: 1;
}

.scc--radio--calibrationMode {
  position: absolute;
  right: 0.8rem;
  top: 0.7rem;
  z-index: 1;
}

.scc--camera-feed--refresh-button {
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  padding: 0;
  position: absolute;
  right: 13rem;
  top: 1rem;
  outline: none;
  z-index: 1;

  &:hover {
    cursor: pointer;

    img {
      transform: rotate(360deg);
    }
  }

  img {
    display: block;
    width: 1rem;
    height: 1rem;
    position: relative;
    left: 0.25rem;
    transition: all 0.3s ease-in-out;
  }
}

.scc--camera-feed--not-configured {
  width: 100%;
  height: 100%;
}
