@import '../../theme/carbon_colors';

.scc--navitem {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}
.scc--navitem:after {
  content: '';
  display: inline-block;
  width: 90%;
  margin-left: 10px;
  border-top: 1px solid darkgray;
}

.scc--navitem--link,
.scc--navitem--link:visited {
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.3rem 0.75rem;
  display: block;
  color: #000000;
  text-decoration: none;

  &:hover,
  &:focus {
    background: #e5e5e5;
  }

  &.active {
    font-weight: bold;

    &:before {
      content: '';
      position: absolute;
      width: 5px;
      top: 0;
      left: 0;
      bottom: 16px;
      background: #000000;
    }
  }
}
