@import '../../../theme/carbon_colors';

// === SKELETON ===

.bx--data-table.bx--skeleton {
  th span,
  td span {
    height: 0.5rem;
    border-radius: 1rem;

    margin: 1.25rem 0;

    &:before {
      border-radius: 1rem;
    }
  }

  th {
    border-bottom: none;
  }

  tr {
    height: 2.5rem;
  }
}

// === EMPTY STATE ===

.scc--scenelist__empty {
  padding: 5rem 1rem;
}

.scc--scenelist__empty--image {
  margin: 3rem 0 1rem;
}

.scc--bg--doves {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: -5px;
    width: 47px;
    height: 32px;
    background: url('../../../theme/icons/doves.svg') no-repeat top left;
    display: block;
    overflow: visible;
    z-index: -1;
  }
}

.scc--scenelist__empty--headline {
  margin-bottom: 1.5rem;
}

.scc--scenelist__empty--support-mail {
  display: block;
  margin: 7rem 0 3.5rem;
}

// === GENERAL ===

.scc--scenelist--container {
  font-size: 16px;
}

.bx--data-table {
  td,
  tbody th,
  tbody {
    background: none;
    color: #898989;
  }

  td {
    padding: 1rem 0.75rem 0.5rem;
  }

  tr {
    height: auto;
    cursor: pointer;
  }

  th {
    vertical-align: middle;
    position: sticky;
    top: 0;
    width: 12%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:first-of-type {
      width: auto;
    }

    &:last-of-type {
      position: sticky;
      width: 12%;
    }
  }

  .bx--table-header-label {
    font-weight: normal;
    font-size: 16px;
    padding: 0.55rem 0 0.35rem;
  }
}

.scc--centered,
.bx--data-table .scc--centered,
.scc--centered .bx--table-header-label {
  text-align: center;
}

.scc--box_name {
  font-weight: bold;
}

.scc--configured,
.scc--connected {
  .color_fill {
    fill: $green;
  }
  .color_stroke {
    stroke: $green;
  }
}

.scc--unknown {
  .color_fill {
    fill: $grey;
  }
  .color_stroke {
    stroke: $grey;
  }
}

.scc--selected,
.scc--selected:hover,
.scc--selected:focus {
  background: lighten($light-green, 15) !important;

  td {
    background: none !important;
    color: #000000 !important;
  }
}

.scc--cell-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
