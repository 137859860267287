@import '../../theme/carbon_colors';

.scc--header {
  background: #fff;
  z-index: 8888;
}

.scc--app--title {
  font-size: 1rem;
  line-height: 3.125;
  letter-spacing: 0.5px;
  color: #000000;

  span {
    font-size: 0.625rem;
    color: $text_light;
    display: inline-block;
    margin-left: 2px;
  }
}

.scc--header--right {
  display: flex;
  justify-content: flex-end;
}
