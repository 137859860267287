// === COLORS ===

$grey: #d3d3d3;
$light_green: #99dcc5;
$green: #00a86b;
$light-green: #a1e0c1;
$hover-green: darken($green, 10);
$focus-green: darken($green, 10);
$text_grey: rgba(0, 0, 0, 0.5);
$text_light: #abb9ca;
$text_dark: #000000;
$skeleton_grey: #d8d8d7;
$form_grey: #f2f5f7;
$error_red: #da1e28;
