@import '../../theme/carbon_colors';

th.scc--centered.bx--table-header-width100 {
  width: 100px !important;
}

.scc--table--action {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.scc--icon {
  color: $green;
  font-size: 0.6rem;
  text-align: center;
  border: 1px solid $green;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
}

.scc--icon--cl {
  border-radius: 50%;
}

.scc--icon--zone {
  width: 1.5rem;
  border: none;
  position: relative;
  line-height: 1.4rem;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -0.05rem;
    left: -0.05rem;
    width: 0.1rem;
    height: 0.15rem;
    border: 1px solid $green;
  }

  &:after {
    top: auto;
    left: auto;
    bottom: -0.05rem;
    right: -0.05rem;
  }
}

.scc--draw--table {
  table-layout: fixed;

  line-height: 1.1rem;

  thead,
  th {
    background: #ffffff;
    border: none;
    color: $text_light;
    position: relative;
    z-index: 1;
    width: auto !important;
  }

  td {
    vertical-align: middle;
  }
}

.scc--nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scc--no-border {
  border: none;
}
